import '@Application/Bootstrap';

import autoload from '@123/druid/dist/Framework/Decorators/Autoload';
import component from '@123/druid/dist/Framework/Decorators/Component';
import View from '@123/druid/dist/Framework/View/View';
import WattageCalculatorView from '@Module/Content/Component/WattageCalculator/WattageCalculatorView';

@autoload
@component
export default class ContentModuleView extends View {
    protected mounted(): void {
        this.getViewOrNull(WattageCalculatorView);
    }
}
