import '@Application/Bootstrap';

import component from '@123/druid/dist/Framework/Decorators/Component';
import onInput from '@123/druid/dist/Framework/Decorators/OnInput';
import View from '@123/druid/dist/Framework/View/View';
import type DelegateEvent from '@123/druid/dist/Types/DelegateEvent';

interface WattageFormInputData {
    lampCount: number;
    lampWattage: number;
    lampLedWattage: number;
    burnHoursDay: number;
    pricePerKwh: number;
}

@component
export default class WattageCalculatorView extends View {
    protected mounted(): void {
        this.initializeFields();
    }

    @onInput('calculator-fields')
    protected onFieldChange(event: DelegateEvent): void {
        this.calculateWattage(event.delegateTarget.getFormData<WattageFormInputData>());
    }

    private initializeFields(): void {
        this.el
            .querySelectorAll('input:not([data-role~=disabled-input])')
            .forEach((element) => (<HTMLInputElement>element).attributeMap().setBool('disabled', false));

        (<HTMLInputElement>this.el.queryRole('price-per-kwh')).value = this.el.datamap().getString('priceKwh', '0.22');
    }

    private calculateWattage({lampCount, lampWattage, lampLedWattage, burnHoursDay, pricePerKwh}: WattageFormInputData): void {
        const burnHoursYear: number = burnHoursDay * 365;
        const lightingCurrentKwh: number = burnHoursYear * (0.001 * (lampCount * lampWattage));
        const lightingCurrentCost: number = pricePerKwh * lightingCurrentKwh;
        const lightingLedKwh: number = burnHoursYear * (0.001 * (lampCount * lampLedWattage));
        const lightingLedCost: number = pricePerKwh * lightingLedKwh;

        (<HTMLInputElement>this.el.queryRole('burn-hours-year')).value = burnHoursYear.toString();

        this.renderResult({
            ResultCurrentKwh: lightingCurrentKwh,
            ResultCurrentEur: pricePerKwh * lightingCurrentKwh,
            ResultLedKwh: lightingLedKwh,
            ResultLedEur: lightingLedCost,
            ResultSavingsKwh: lightingCurrentKwh - lightingLedKwh,
            ResultSavingsEur: lightingCurrentCost - lightingLedCost,
        });
    }

    private renderResult(data: Record<string, number>): void {
        Object.entries(data).forEach(([dataRole, value]) => {
            this.el.queryRole(dataRole).innerHTML = value.toFixed(2).replace(/,/g, '.');
        });
    }
}
